import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'

import App from './App'

void loadableReady(() => {
  hydrateRoot(
    document.querySelector('#app')!,
    <BrowserRouter basename={process.env.BASENAME}><App /></BrowserRouter>
  )
})
