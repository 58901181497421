import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import urlJoin from 'url-join'
import loadable from '@loadable/component'

import './styles.scss'

const Home = loadable(async () => await import('./pages/Home'))
const Tags = loadable(async () => await import('./pages/Tags'))
const CalendarGenerator = loadable(async () => await import('./pages/CopyAndPaste/CalendarGenerator'))
const Checkmarks = loadable(async () => await import('./pages/CopyAndPaste/Checkmarks'))
const EmojiHoroscope = loadable(async () => await import('./pages/CopyAndPaste/EmojiHoroscope'))
const ChessSymbols = loadable(async () => await import('./pages/CopyAndPaste/ChessSymbols'))
const Interrobang = loadable(async () => await import('./pages/CopyAndPaste/Interrobang'))
const SnowflakeSymbols = loadable(async () => await import('./pages/CopyAndPaste/SnowflakeSymbols'))
const StarSymbols = loadable(async () => await import('./pages/CopyAndPaste/StarSymbols'))
const Whitespace = loadable(async () => await import('./pages/CopyAndPaste/Whitespace'))
const Backwards = loadable(async () => await import('./pages/FontGenerator/Backwards'))
const Vaporwave = loadable(async () => await import('./pages/FontGenerator/Vaporwave'))
const Spongebob = loadable(async () => await import('./pages/FontGenerator/Spongebob'))
const Upsidedown = loadable(async () => await import('./pages/FontGenerator/Upsidedown'))
const Zalgo = loadable(async () => await import('./pages/FontGenerator/Zalgo'))
const Cursive = loadable(async () => await import('./pages/FontGenerator/Cursive'))
const Superscript = loadable(async () => await import('./pages/FontGenerator/Superscript'))
const Bold = loadable(async () => await import('./pages/FontGenerator/Bold'))
const Italic = loadable(async () => await import('./pages/FontGenerator/Italic'))
const SmallCaps = loadable(async () => await import('./pages/FontGenerator/SmallCaps'))
const Strikethrough = loadable(async () => await import('./pages/FontGenerator/Strikethrough'))
const Underline = loadable(async () => await import('./pages/FontGenerator/Underline'))
const Fancy = loadable(async () => await import('./pages/FontGenerator/Fancy'))
const Gothic = loadable(async () => await import('./pages/FontGenerator/Gothic'))
const Square = loadable(async () => await import('./pages/FontGenerator/Square'))
const Circle = loadable(async () => await import('./pages/FontGenerator/Circled'))
const Typewriter = loadable(async () => await import('./pages/FontGenerator/Typewriter'))
const Outline = loadable(async () => await import('./pages/FontGenerator/Outline'))
const SansSerif = loadable(async () => await import('./pages/FontGenerator/SansSerif'))
const Serif = loadable(async () => await import('./pages/FontGenerator/Serif'))

const App: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="gb-font-maker-scope">
      <Helmet>
        <link rel="canonical" href={urlJoin(process.env.BASE_URL!, process.env.BASENAME!, location.pathname)} />
      </Helmet>
      <Routes>
        <Route path="/" Component={Home} />

        <Route path="/calendar/" Component={CalendarGenerator} />
        <Route path="/emoji-horoscope/" Component={EmojiHoroscope} />
        <Route path="/chess-symbols/" Component={ChessSymbols} />
        <Route path="/checkmarks/" Component={Checkmarks} />

        <Route path="/interrobang/" Component={Interrobang} />
        <Route path="/snowflake-text-symbols/" Component={SnowflakeSymbols} />
        <Route path="/star-symbols/" Component={StarSymbols} />
        <Route path="/whitespace/" Component={Whitespace} />
        <Route path="/tag/:id/" Component={Tags} />

        <Route path="/backwards-text/" Component={Backwards} />
        <Route path="/vaporwave-text/" Component={Vaporwave} />
        <Route path="/spongebob/" Component={Spongebob} />
        <Route path="/upside-down-text/" Component={Upsidedown} />
        <Route path="/zalgo-text/" Component={Zalgo} />

        <Route path="/cursive/" Component={Cursive} />
        <Route path="/superscript/" Component={Superscript} />
        <Route path="/bold-text/" Component={Bold} />
        <Route path="/italic/" Component={Italic} />
        <Route path="/small-caps/" Component={SmallCaps} />
        <Route path="/strikethrough/" Component={Strikethrough} />
        <Route path="/underline-text/" Component={Underline} />

        <Route path="/fancy/" Component={Fancy} />
        <Route path="/gothic/" Component={Gothic} />
        <Route path="/square/" Component={Square} />
        <Route path="/circled/" Component={Circle} />
        <Route path="/typewriter/" Component={Typewriter} />
        <Route path="/outline/" Component={Outline} />
        <Route path="/sans-serif/" Component={SansSerif} />
        <Route path="/serif/" Component={Serif} />
      </Routes>
    </div>
  )
}

export default App
